import React from "react"
import { compose, withProps } from "recompose"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: (
      <div
        style={{
          height: `550px`,
          width: "800px",
          // width: window && window.innerWidth < 400 ? "375px" : "800px",

        }}
      />
    ),
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={16}
    defaultCenter={{ lat: 21.230682, lng: 72.906974 }}
  >
    {props.isMarkerShown && (
      <Marker position={{ lat: 21.230682, lng: 72.906974 }} />
    )}
  </GoogleMap>
))

export default MyMapComponent
