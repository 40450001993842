import React, { Component } from "react"

import "../styles/contact-us.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import contactusBanner from "../images/contact-us-banner-icon.png"

import mapImg from "../images/map.jpg"
import phonIcon from "../images/phone-icon.png"
import emailIcon from "../images/email-icon.png"
import specialiteWebsite from "../images/specialite_maison_website.png"
import specialiteMobile from "../images/specialite_maison_mobile.png"
import specialiteDesktop from "../images/specialite_maison_desktop.png"
import specialiteBackend from "../images/specialite_maison_backend.png"
// import { Link } from "gatsby"
import MyMapComponent from "../components/myMapComponent"

import * as emailjs from "emailjs-com"

emailjs.init(process.env.GATSBY_EMAILJS_USER_ID)
export default class ContactPage extends Component {
  constructor() {
    super()
    this.state = {
      name: "",
      email: "",
      mobile: "",
      message: "",
      isShowError: false,
      isShowSuccessMessage: false,
      isEmailNotValid: false,
    }
  }
  handleChangeInputText = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleSubmitContactForm = e => {
    e.preventDefault()

    const { mobile, name, email, message } = this.state

    if (name && email && mobile) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        const body = { name, email, mobile, message }
        this.setState({ isShowError: false })
        emailjs.send(
          process.env.GATSBY_EMAILJS_SERVICE,
          process.env.GATSBY_EMAILJS_TEMPLATE_ID,
          body
        )
        this.setState({
          isShowError: false,
          isShowSuccessMessage: true,
          isEmailNotValid: false,
          name: "",
          email: "",
          mobile: "",
          message: "",
        })
      } else {
        this.setState({ isShowError: false, isEmailNotValid: true })
      }
    } else {
      this.setState({
        isShowError: true,
        isShowSuccessMessage: false,
        isEmailNotValid: false,
      })
    }
  }

  render() {
    const {
      mobile,
      name,
      email,
      message,
      isShowError,
      isShowSuccessMessage,
      isEmailNotValid,
    } = this.state

    return (
      <Layout>
        <SEO title="Contact us" />

        <section className="wrapper top_banner">
          <div className="container">
            <div className="wrapper top_banner_slider">
              <div className="wrapper colwrap">
                <div className="textwrap">
                  <h1 className="h1">
                    Find out where to meet up and talk to us.
                  </h1>
                </div>
                <div className="imgwrap">
                  <img src={contactusBanner} alt="" />
                </div>
              </div>
            </div>
            <a href="#development">
              <abbr className="down_arrow"></abbr>
            </a>
          </div>
        </section>

        {/* <section className="wrapper banner_copy_text" id="development">
          <div className="container">
            <h4 className="wrapper h4">
              We combine the advice of business and technology of last
              generation, to create solutions to the extent of their world. And
              that makes us unique.
            </h4>
          </div>
        </section> */}

        <section className="wrapper contact_map_form" style={{ justifyContent: 'center' }}>
          <div className="contact_form">
            <div className="innerwrap">
              <h3 className="h3">Tell us more about how we can help you</h3>
              <form className="wrapper">
                <ul>
                  <li>
                    <input
                      type="text"
                      placeholder="Name"
                      name="name"
                      onChange={this.handleChangeInputText}
                      value={name}
                    />
                    <span
                      className="wrapper errormsg"
                      style={{
                        visibility: isShowError && !name ? "visible" : "hidden",
                      }}
                    >
                      Please enter name.
                    </span>
                  </li>

                  <li>
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={this.handleChangeInputText}
                    />
                    <span
                      className="wrapper errormsg"
                      style={{
                        visibility:
                          isShowError && !email ? "visible" : "hidden",
                      }}
                    >
                      Please enter email id.
                    </span>
                    {isEmailNotValid && (
                      <span className="wrapper errormsg">
                        Please enter valid email id.
                      </span>
                    )}
                  </li>

                  <li>
                    <input
                      type="text"
                      value={mobile}
                      placeholder="Contact No"
                      name="mobile"
                      onChange={this.handleChangeInputText}
                    />
                    <span
                      className="wrapper errormsg"
                      style={{
                        visibility:
                          isShowError && !mobile ? "visible" : "hidden",
                      }}
                    >
                      Please enter contact no.
                    </span>
                  </li>

                  <li>
                    <textarea
                      rows="4"
                      cols="50"
                      placeholder="Message"
                      name="message"
                      value={message}
                      onChange={this.handleChangeInputText}
                    >
                      {/* Message */}
                    </textarea>
                  </li>

                  {/* <li>
                    <div className="form-group">
                      <input type="checkbox" id="agree" />
                      <label htmlFor="agree">
                        I understand and agree to this
                        <a href="javascript:void(0)">
                          <span>privacy policy.</span>
                        </a>
                      </label>
                    </div>
                  </li> */}

                  {isShowSuccessMessage && (
                    <span style={{ color: "green" }}>
                      We got your message, will get back to you soon.
                    </span>
                  )}

                  <li>
                    <button
                      type="submit"
                      className="btn2"
                      onClick={this.handleSubmitContactForm}
                    >
                      Submit
                    </button>
                  </li>
                </ul>
              </form>
            </div>
          </div>
          {/* <div className="contact_map">
            <span className="map_iframe">
              <img src={mapImg} alt="" />
              <MyMapComponent isMarkerShown />
              <abbr className="map_pointer_mobile"></abbr>
            </span>

            <div className="address_box">
              <abbr className="map_pointer"></abbr>
              <div className="detail_address">
                <strong className="wrapper">Albertios</strong>
                <p className="wrapper">
                  406, Avadh viceroy, Sarthana Jakatnaka
                </p>
                <ul className="wrapper get_in_touch_link">
                  <li>
                    <a href="tel:9978618171">
                      <span>
                        <img src={phonIcon} alt="" /> +91 9978618171
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:hello@albetrios.com">
                      <span>
                        <img src={emailIcon} alt="" /> hello@albetrios.com
                      </span>
                    </a>
                  </li>
                </ul>
                <a href="javascript:void(0)">
                  <abbr className="wrapper">SEE DIRECTION</abbr>
                </a>
              </div>
            </div>
          </div>
         */}
        </section>

        <section className="wrapper services_wrap">
          <div className="wrapper services_heading">
            <div className="container">
              <h2 className="wrapper h2">Our Services</h2>
            </div>
          </div>
          <div className="wrapper services_col">
            <div className="container">
              <ul>
                <li>
                  <span className="circle_img">
                    <img src={specialiteWebsite} alt="" />
                  </span>
                  <h4 className="wrapper h4">Web Application Development</h4>
                </li>

                <li>
                  <span className="circle_img">
                    <img src={specialiteMobile} alt="" />
                  </span>
                  <h4 className="wrapper h4">Mobile Application Development</h4>
                </li>

                <li>
                  <span className="circle_img">
                    <img src={specialiteDesktop} alt="" />
                  </span>
                  <h4 className="wrapper h4">
                    Desktop Application Development
                  </h4>
                </li>

                <li>
                  <span className="circle_img">
                    <img src={specialiteBackend} alt="" />
                  </span>
                  <h4 className="wrapper h4">Backend Development</h4>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

// <section classNameName="wrapper top_banner">
// <div classNameName="container">
//   <div classNameName="wrapper top_banner_slider">
//     <div classNameName="wrapper colwrap">
//       <div classNameName="textwrap">
//         <h1 classNameName="h1">
//           Find out where to meet up and talk to us.
//         </h1>
//       </div>
//       <div classNameName="imgwrap">
//         <img src={contactusBanner} alt="" />
//       </div>
//     </div>
//   </div>
//   <a href="#development">
//     <abbr classNameName="down_arrow"></abbr>
//   </a>
// </div>
// </section>

// <section classNameName="wrapper banner_copy_text" id="development">
// <div classNameName="container">
//   <h4 classNameName="wrapper h4">
//     We combine the advice of business and technology of last
//     generation, to create solutions to the extent of their world. And
//     that makes us unique.
//   </h4>
// </div>
// </section>

// <section classNameName="wrapper contact_map_form">
// <div classNameName="contact_form">
//   <div classNameName="innerwrap">
//     <h3 classNameName="h3">Tell us more about how we can help you</h3>
//     <form classNameName="wrapper">
//       <ul>
//         <li>
//           <input type="text" placeholder="Name" />
//           <span classNameName="wrapper errormsg">Please enter name</span>
//         </li>

//         <li>
//           <input type="text" placeholder="Email" />
//           <span classNameName="wrapper errormsg">
//             Please enter email id
//           </span>
//         </li>

//         <li>
//           <input type="text" placeholder="Contact No" />
//           <span classNameName="wrapper errormsg">
//             Please enter contact no
//           </span>
//         </li>

//         <li>
//           <textarea rows="4" cols="50">
//             Message
//           </textarea>
//         </li>

//         <li>
//           <div classNameName="form-group">
//             <input type="checkbox" id="agree" />
//             <label for="agree">
//               I understand and agree to this
//               <a href="javascript:void(0)">
//                 <span>privacy policy.</span>
//               </a>
//             </label>
//           </div>
//         </li>

//         <li>
//           <button type="submit" classNameName="btn2">
//             Submit
//           </button>
//         </li>
//       </ul>
//     </form>
//   </div>
// </div>
// <div classNameName="contact_map">
//   <span classNameName="map_iframe">
//     {/* <img src={mapImg} alt="" /> */}
//     <MyMapComponent isMarkerShown />
//   </span>
//   <div classNameName="address_box">
//     {/* <abbr classNameName="map_pointer"></abbr> */}
//     <div classNameName="detail_address">
//       <strong classNameName="wrapper">Albertios</strong>
//       <p classNameName="wrapper">
//         406, Avadh viceroy, Sarthana Jakatnaka
//       </p>
//       <ul classNameName="wrapper get_in_touch_link">
//         <li>
//           <a href="tel:9978618171">
//             <span>
//               <img src={phonIcon} alt="" /> +91 9978618171
//             </span>
//           </a>
//         </li>
//         <li>
//           <a href="mailto:hello@albetrios.com">
//             <span>
//               <img src={emailIcon} alt="" /> hello@albetrios.com
//             </span>
//           </a>
//         </li>
//       </ul>
//       <a
//         href="https://www.google.com/maps/dir//Avadh+Viceroy,+Sarthana+Jakat+Naka+-+Kamrej+Rd,+Sarthana+Jakat+Naka,+Subhash+Nagar,+Nana+Varachha,+Surat,+Gujarat+395006,+India/@21.230947,72.904253,19z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3be045f4b61ead47:0x8b235392ebd116f4!2m2!1d72.9032616!2d21.231027?hl=en-GB"
//         target="_blank"
//       >
//         <abbr classNameName="wrapper">SEE DIRECTION</abbr>
//       </a>
//     </div>
//   </div>
// </div>
// </section>

// <section classNameName="wrapper services_wrap">
// <div classNameName="wrapper services_heading">
//   <div classNameName="container">
//     <h2 classNameName="wrapper h2">JS is our Spécialité de la maison</h2>
//   </div>
// </div>
// <div classNameName="wrapper services_col">
//   <div classNameName="container">
//     <ul>
//       <li>
//         <span classNameName="circle_img">
//           <img src={specialiteWebsite} alt="" />
//         </span>
//         <h4 classNameName="wrapper h4">Web Application Development</h4>
//       </li>

//       <li>
//         <span classNameName="circle_img">
//           <img src={specialiteMobile} alt="" />
//         </span>
//         <h4 classNameName="wrapper h4">Mobile Application Development</h4>
//       </li>

//       <li>
//         <span classNameName="circle_img">
//           <img src={specialiteDesktop} alt="" />
//         </span>
//         <h4 classNameName="wrapper h4">
//           Desktop Application Development
//         </h4>
//       </li>

//       <li>
//         <span classNameName="circle_img">
//           <img src={specialiteBackend} alt="" />
//         </span>
//         <h4 classNameName="wrapper h4">Backend Development</h4>
//       </li>
//     </ul>
//   </div>
// </div>
// </section>
